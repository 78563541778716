<template lang="pug">
  div.row
    div.col-12
      div
        table.table.table-striped.table-bordered.users-table
          thead.sticky-top.bg-white
            tr
              th(style="width:40px;") ID
              th(style="width:140px;") Cancel
              th Days
              th.text-start Name
              th.text-start Email
              th.text-start Plan
              th
                span(data-feather="twitter")
              th 未対応
              th 需要×
              th 使いにくい
              th 高い
              th 乗換え
              th その他
          tbody
            tr(v-for="u in users", :key="u.id")
              td 
                router-link(:to="{ path:'user', query:{ id:u.id } }") {{ u.id }}
              td
                span(:class="u.force? 'text-decoration-line-through':''") {{ u.cancel | moment }}
              td 
                span(:class="u.force? 'text-decoration-line-through':''") {{ Math.floor((u.cancel-u.signup)/3600/24) }}
              td.text-start {{ u.name }}
              td.text-start {{ u.email }}
              td.text-start {{ u.plan }}
              td.text-start {{ u.twitter }}
              td
                <svg v-show="u.reasons.includes(1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" data-v-c2e97fca=""><polyline points="20 6 9 17 4 12"></polyline></svg>
              td
                <svg v-show="u.reasons.includes(2)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" data-v-c2e97fca=""><polyline points="20 6 9 17 4 12"></polyline></svg>
              td 
                <svg v-show="u.reasons.includes(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" data-v-c2e97fca=""><polyline points="20 6 9 17 4 12"></polyline></svg>
              td 
                <svg v-show="u.reasons.includes(4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" data-v-c2e97fca=""><polyline points="20 6 9 17 4 12"></polyline></svg>
              td 
                <svg v-show="u.reasons.includes(5)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" data-v-c2e97fca=""><polyline points="20 6 9 17 4 12"></polyline></svg>
              td 
                a(v-if="u.note", href="#", @click="show_note($event, u.note)") Open
</template>

<script>
import feather from 'feather-icons';
import moment from 'moment';

export default {
  name:"CanceledUsers",
  data (){
    return {
      users:[
      ]
    }
  },
  computed :{
  },
  mounted (){
    feather.replace();
    this.get("/api/cancel");
  },
  methods: {
    get (url){
      this.axios.get(url)
      .then((response) => {
        this.users = response.data;
        this.users.sort((a,b)=>b.cancel-a.cancel);
      })
    },
    async show_note (e, note){
      e.target.parentElement.parentElement.style.outlineOffset = "-2px";
      e.target.parentElement.parentElement.style.outline = "solid 2px blue";
      await this.$swal(note);
      e.target.parentElement.parentElement.style.outline = "unset";
      e.target.parentElement.parentElement.style.outlineOffset = "unset";
    }
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.users-table {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
  .usage {
    width: 60px;
  }
  .feather {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-top: 1px;
  }
}
</style>